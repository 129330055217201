<template>
    <div class="kteventsection5_container">
        <div class="ktevent_section5_noticelist dp_flex flex_column">
            <span class="kteventsection5_notice_text">유의사항</span>
            <div class="ktevents_section5_noticelist dp_flex flex_column">
                <div class="kteventsection5_notice_item">
                    <span class="dot"></span>

                    <div class="notice_description">
                        해당 이벤트는 KT에서 주최하는 이벤트이며, 당사는 본 이벤트 홍보를 목적으로 배너를 게시하며, 이벤트의 내
                        변경사항, 취소 등에 대해서 책임지지 않습니다.
                    </div>
                </div>
                <div class="kteventsection5_notice_item">
                    <span class="dot"></span>

                    <div class="notice_description">
                        이벤트 정보는 타사로부터 제공받은 내용을 기반으로 하며, 실제 이벤트 정보와 다를 수 있습니다.
                    </div>
                </div>
                <div class="kteventsection5_notice_item">
                    <span class="dot"></span>

                    <div class="notice_description">
                        이벤트 관련 문의는 주최측에 직접 문의 바랍니다.
                    </div>
                </div>

                <div class="kteventsection5_notice_item">
                    <span class="dot"></span>

                    <div class="notice_description">
                        본 페이지에서는 이벤트 참여를 위한 개인정보를 수집하지 않으며, 이벤트 참여는 타사 웹사이트를 통해 진행해
                        주시기 바랍니다.
                    </div>
                </div>

                <div class="kteventsection5_notice_item">
                    <span class="dot"></span>

                    <div class="notice_description">
                        타사 이벤트 종료 시, 또는 부적절한 내용이 발견되거나 법적 문제가 발생할 경우, 당사는 본 배너를 즉시 제거할
                        수 있습니다.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'KtEventSection5',

  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped src="@/assets/css/collaboration/kteventsection5.css"></style>
