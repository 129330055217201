import { render, staticRenderFns } from "./KtEventSection5.vue?vue&type=template&id=3abf6a64&scoped=true"
import script from "./KtEventSection5.vue?vue&type=script&lang=js"
export * from "./KtEventSection5.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/collaboration/collaborfont.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/css/collaboration/kteventsection5.css?vue&type=style&index=1&id=3abf6a64&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3abf6a64",
  null
  
)

export default component.exports